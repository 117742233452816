import { updateLeasingCompanyThresholdsOverviewFilter, updateLeasingCompanyThresholdsOverviewPaginator, updateLeasingCompanyThresholdsOverviewSort } from '@app/leasing-company/leasing-company-thresholds/overview/state/overview.actions';
import { LeasingCompanyThresholdParameters } from '@app/leasing-company/leasing-company-thresholds/shared/models/leasing-company-threshold-parameters';
import { rsCountFilters } from '@lib/utils/count-filters/count-filters';
import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';

export const leasingCompanyThresholdOverviewFeatureKey = 'leasingCompanyThresholdOverview';

export interface LeasingCompanyThresholdOverviewState {
  params: LeasingCompanyThresholdParameters;
  numberOfFilters: number;
}

export const leasingCompanyThresholdInitialState: LeasingCompanyThresholdOverviewState = {
  params: {
    page: 1,
    resultsPerPage: 10,
    sortBy: 'ACTIVITY_DESCRIPTION',
    sortDirection: 'ASC',

    activityCodeFrom: null,
    activityCodeTo: null,
    activityDescription: null,
    febiacBrandCode: null,
    firstRegistrationStartingFrom: null,
    febiacCylinderContent: null,
    febiacFuelCode: null,
    febiacModel: null,
    febiacPower: null,
    febiacTransmission: null,
    subleaseCode: null,
    thresholdType: null,
    supplierGroupId: null,
  },
  numberOfFilters: 0
};


export const leasingCompanyThresholdOverviewReducer = createReducer(
  leasingCompanyThresholdInitialState,

  on(updateLeasingCompanyThresholdsOverviewFilter, (state, { params }) => {
    const numberOfFilters = rsCountFilters(params, [
      'activityCodeFrom',
      'activityCodeTo',
      'activityDescription',
      'febiacBrandCode',
      'firstRegistrationStartingFrom',
      'febiacCylinderContent',
      'febiacFuelCode',
      'febiacModel',
      'febiacPower',
      'febiacTransmission',
      'subleaseCode',
      'thresholdType',
      'supplierGroupId',
    ]);

    return produce(state, draft => {
      Object.assign(draft.params, params);
      draft.params.page = 1;
      draft.numberOfFilters = numberOfFilters;
    });
  }),


  on(updateLeasingCompanyThresholdsOverviewPaginator, (state, {
    resultsPerPage, page
  }) => {
    const newPage = state.params?.resultsPerPage != resultsPerPage ? 1 : page;
    return { ...state, params: { ...state.params, resultsPerPage, page: newPage } };
  }),
  on(updateLeasingCompanyThresholdsOverviewSort, (state, {
    sortDirection, sortBy
  }) => {
    return { ...state, params: { ...state.params, sortBy, sortDirection } };
  })
);

