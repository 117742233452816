import { MrtEnvironment } from '@environments/mrt-environment';
import { isMobiSkin, RentaDomains } from '@lib/rs-ngx';

export const environment: MrtEnvironment = {
  production: false,
  apiUrl: `https://api.mrt.tst.${RentaDomains.RENTA}/mrt-api`,
  apiBatchUrl: `https://api.mrt.tst.${RentaDomains.RENTA}/mrt-api`,
  useMockedServices: false,
  enableSentryMonitoring: false,
  sentryReleaseName: 'test-mrt-ui-12354',
  environment: 'test',
  topBarEnvironment: 'tst',
  topBarCdn: 'https://cdn.rentasolutions.org/topbar/tst/v1.3/',
  weblateEnvironment: 'tst',
  authUrl: '',
  legacyUiUrl: 'https://api.mrt.tst.rentasolutions.org/renta/?portal=true&Login=Continue',
};

if (isMobiSkin) {
  Object.assign(environment, {
    apiUrl: `https://api.tech.tst.${RentaDomains.EUROPE}`,
    apiBatchUrl: `https://batch-api.tech.tst.${RentaDomains.EUROPE}`,
    legacyUiUrl: null,
  });
}
